// VARIÁVEIS
@import "./app/theme/_variables";

// ESPAÇOS
@import "./app/theme/_spacing";

// TAMANHO FONTE
@import "./app/theme/_fontSize";


.fc-right {
  display: none !important;
}

html,
body {
  height: 100%;
  width: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.font-tamanho-16 {
  font-size: 1.6rem;
}

.font-tamanho-15 {
  font-size: 1.5rem;
}

.font-tamanho-14 {
  font-size: 1.4rem;
}

.font-tamanho-13 {
  font-size: 1.3rem;
}

.font-tamanho-12 {
  font-size: 1.2rem;
}

.font-tamanho-11 {
  font-size: 1.1rem;
}

.font-tamanho-10 {
  font-size: 1rem;
}

.font-tamanho-9 {
  font-size: 0.9rem;
}

.font-tamanho-8 {
  font-size: 0.8rem;
}

.font-tamanho-7 {
  font-size: 0.7rem;
}

.font-tamanho-6 {
  font-size: 0.6rem;
}

.font-tamanho-5 {
  font-size: 0.5rem;
}

.modal-full {
  min-width: 95%;
  // margin: 0;
}

.modal {
  background: rgba(0, 0, 0, 0.5);
}

.mt-28 {
  margin-top: 28px;
}


// Botões - Início

.btn-success {
  background-color: $cor-padrao !important;
  border-color: $cor-padrao !important;

  &:disabled {
    color: $branco;
    background-color: $cor-padrao;
    border-color: $cor-padrao;
  }
}

.btn-outline-success {
  color: $cor-padrao;
  border-color: $cor-padrao;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: $cor-padrao;
  border-color: $cor-padrao;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: $cor-padrao;
  border-color: $cor-padrao;
}

.btn-custom {
  flex: 1 1 auto;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  border-radius: 10px;
  font-weight: 500;
  font-size: 1rem;
}

.btn:hover {
  background-position: right center;
}

.btn-gradient {
  background-image: $gradient;
}

.bg-success {
  background-color: $cor-padrao !important;
}

.bg-white {
  background-color: $branco;
}

.bg-gradient {
  background-image: $gradient;
}

.bg-grey-100 {
  background-color: $cinza-1;
}

.bg-grey-400 {
  background-color: $cinza-4;
}

.btn-gradient:hover {
  background: $verde-escuro !important;
}

.scrool_table {
  width: 100%;
  overflow-x: scroll;
  position: relative;
}

.block-ui-wrapper {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9) !important;
  z-index: 30000;
  cursor: wait;
}

/* Card Inicio */
.card {
  height: 100%;
  -webkit-box-shadow: 0 9px 23px rgba(0, 0, 0, 0.13),
    0 5px 5px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.13), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
  -webkit-transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  -o-transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  -webkit-transition: -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  border-radius: 0.4167rem;
}

.card-nobordered {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0.4167rem;
}

.card-stats {
  /* height: 100%; */
  -webkit-box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09),
    0 5px 5px rgba(0, 0, 0, 0.06) !important;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
  -webkit-transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  -o-transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  -webkit-transition: -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  transition: box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1),
    -webkit-box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
  border-radius: 0.4167rem;
}

/* Card Fim */

// table
// .table > thead > tr > th,
// .table > tbody > tr > th,
// .table > tfoot > tr > th,
// .table > thead > tr > td,
// .table > tbody > tr > td,
// .table > tfoot > tr > td {
//   padding: 0.53%;
// }
// fim table
// Accordion ngx-bootstrap - Inicio

accordion {
  .panel {
    // background-color: $cor-padrao;

    .panel-heading {
      background-color: rgba(0, 0, 0, 0.03);

      .panel-title {
        color: $verde-escuro !important;

        .btn {
          line-height: 0;
        }

        .btn-link {
          color: $cor-padrao;
          font-weight: 600;
          font-size: 14px !important;
        }
      }
    }

    .panel-body {
      background-color: $branco;
    }
  }
}

// Accordion ngx-bootstrap - Fim

// Tab ngx-bootstrap - Início

tabset {

  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link.active,
  .nav-tabs>li.active>a,
  .nav-tabs>li.active>a:hover,
  .nav-tabs>li.active>a:focus,
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs.nav-justified>.active>a,
  .nav-tabs.nav-justified>.active>a:hover,
  .nav-tabs.nav-justified>.active>a:focus {
    color: $branco;
  }

  .nav-tabs {
    border-bottom: 1px solid $cor-padrao;
    background: $branco;
    border-radius: 5px 5px 0 0;

    .nav-link {
      color: $cinza-4;
      text-decoration: none;
      background-color: transparent;
    }

    .nav-link.active {
      color: $branco;
      background-color: $cor-padrao;
      border-color: $cor-padrao;
    }

    .nav-link:hover {
      color: $cor-padrao;
      border-bottom-color: $cor-padrao;
    }

    // .nav-link:focus {
    //   color: $branco;
    //   border-bottom-color: $cor-padrao;
    // }
  }
}

tabset .nav-tabs {
  border-bottom: 1px solid #02a96b;
  background: #ffffff;
  border-radius: 5px 5px 0 0;
  margin: 0 5px;
}

.tab-content {
  padding: 0;
  // margin-bottom: 20px;
  // background: #fff;
  // border-radius: 3px;
}

@media screen and (max-width: 997px) {
  tabset {

    .nav-tabs {

      .nav-link {
        font-size: 12px;
      }
    }

    ;

    .tab-pane .card {
      padding: 5px;
    }

  }

}


// Tab ngx-bootstrap - Fim

// Pagination ngx-bootstrap - Início
.page-item.active .page-link {
  background-color: $cor-padrao !important;
  border-color: $cor-padrao !important;
}

.page-link {
  color: $cor-padrao;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
  background: $verde-escuro !important;
  border-color: $verde-escuro !important;
  color: #fff;
}

// Pagination ngx-bootstrap - Fim

// ng-select - Início
.ng-select.ng-select-single.sm .ng-select-container {
  height: 31px;
}

.ng-select.sm .ng-select-container {
  min-height: 31px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 38px;
}

.ng-select {

  .ng-select-container {
    min-width: 100px;
    width: auto;
  }

  .ng-dropdown-panel {
    width: auto;
  }
}

// ng-select - Fim

.text-badge {
  font-size: 0.9rem;
  font-weight: normal;
}

// toggle

.switch1 {
  --uiSwitchSize: var(--switchSize, 40px);
  --uiSwitchBgColor: var(--switchBgColor, red);
  --uiSwitchBgColorActive: var(--switchBgColorActive, $cor-padrao);
  --uiSwitchBorderColorActive: var(--switchBorderColorActive, #fff);
  --uiSwitchButtonBgColor: var(--switchButtonBgColor, #fff);

  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch2 {
  --uiSwitchSize: var(--switchSize, 40px);
  --uiSwitchBgColor: var(--switchBgColor, $cor-padrao);
  --uiSwitchBgColorActive: var(--switchBgColorActive, red);
  --uiSwitchBorderColorActive: var(--switchBorderColorActive, #fff);
  --uiSwitchButtonBgColor: var(--switchButtonBgColor, #fff);

  display: inline-block;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch__label {
  display: block;
  width: 100%;
  height: 100%;
}

.switch__toggle {
  width: 0;
  height: 0;
  opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
}

.switch__toggle:focus~.switch__label {
  box-shadow: 0 0 0 var(--uiSwitchThickFocus, 4px) var(--uiSwitchBorderColorFocus);
}

.switch__toggle:checked:focus~.switch__label {
  box-shadow: 0 0 0 var(--uiSwitchThickFocus, 4px) var(--uiSwitchBorderColorActive);
}

.switch__label:before,
.switch__label:after {
  content: "";
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
}

.switch__label:before {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--uiSwitchBgColor);
}

.switch__label:after {
  top: 50%;
  z-index: 3;
  transition: transform 0.4s cubic-bezier(0.44, -0.12, 0.07, 1.15);
}

/* type 1 */

.switch_type1 {
  --uiSwitchBorderRadius: var(--switchBorderRadius, 60px);

  width: var(--uiSwitchSize);
  height: calc((var(--uiSwitchSize) / 2));
  border-radius: var(--uiSwitchBorderRadius);
  background-color: var(--uiSwitchBgColorActive);
}

.switch_type1 .switch__label {
  border-radius: var(--uiSwitchBorderRadius);
}

.switch_type1 .switch__label:before {
  border-radius: var(--uiSwitchBorderRadius);
  transition: opacity 0.2s ease-out 0.1s, transform 0.2s ease-out 0.1s;
  transform: scale(1);
  opacity: 1;
}

.switch_type1 .switch__toggle:checked~.switch__label:before {
  transform: scale(0);
  opacity: 0.7;
}

.switch_type1 .switch__label:after {
  width: calc(var(--uiSwitchSize) / 2);
  height: calc(var(--uiSwitchSize) / 2);
  transform: translate3d(0, -50%, 0);

  background-color: var(--uiSwitchButtonBgColor);
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.switch_type1 .switch__toggle:checked~.switch__label:after {
  transform: translate3d(100%, -50%, 0);
}

.card-custom {
  border-color: $cor-padrao !important;
}

.itens {
  color: $cor-padrao;
  font-size: 1.2rem;
}

.badge.badge-success,
.label.label-success,
.badge.badge-teal,
.label.label-teal {
  background: $cor-padrao;
}

.dropzone {
  border: 1px dashed #b6c2c9 !important;
}

.dropzone .dz-message {
  text-align: center !important;
  margin: 8px 0px !important;
  border: 1.4px dashed #aaa !important;
}

.dropzone .dz-message {
  font-weight: 300 !important;
  color: inherit !important;
  font-size: 15px;
}

.h5,
h5 {
  font-size: 1.5rem !important;
}

.table thead th {
  border-bottom: 2px solid #dee2e6 !important;
}

// .card-body,
// .card {
//   padding: 1rem;
// }

.navbar-logo {
  color: #fff !important;
}

.text-success {
  color: $cor-padrao !important;
}

.text-muted {
  color: #868e96 !important;
}

.wrapper {
  padding: 100px;
}

.image--cover {
  object-fit: cover;
  object-position: center right;
}

.image--cover {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  border: 3.5px solid $cor-padrao;
  margin-bottom: 4%;
}

.emailUsuario {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
  font-weight: normal;
}

.sidebar .nav>li.active>a i {
  color: $cor-padrao;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $cor-auxiliar !important;
  background-color: $cor-auxiliar !important;
}

.custom-control-label {
  padding-top: 4px;
}

// .letrasIniciaisEmpresa{
//   font-weight: normal;
//   background: rgba(46,46,46,1);
//   font-size: 1.6rem !important;
//   padding: .8rem;
//   color: #fff;
//   border-radius: 5px;
// }
.border-shadow {
  border-bottom: 1px solid #889097 !important;
}

.corAuxilixar {
  background-color: $cor-auxiliar !important;
}

.logo {
  margin-top: 115%;
}

.sidebar-minify-btn {
  background: #333 !important;
}

.sidebar-minify-btn i {
  color: #fff;
}

.has-success .form-control,
.custom-select.is-valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.was-validated .form-control:valid {
  border-color: #18b7db !important;
  box-shadow: none;
}

.customBg {
  background-color: #f0f1f2 !important;
  color: #000 !important;
}

.page-header-fixed #header {
  position: fixed;
}

.detalhar {
  border-radius: 6px;
}

.badge-info {
  padding: 5px;
}

.ng2-tag-input {
  padding: 0rem 0 !important;
  min-height: 15px !important;
  border: 1px solid #ced4da !important;
  border-radius: 3px;
}

tag {
  line-height: 29px !important;
}

.btn-white {
  color: inherit;
  background: #fff;
  border: 1px solid #e7eaec;
}

// .table > tbody > tr > td{
//   padding: 10px 15px;
// }

.display-none {
  display: none !important;
}

.dropdown-item:hover {
  background-color: $cor-padrao;
  color: $branco;
}

// fim sobrescrição

// Calendario Inicio

dp-calendar-nav.dp-material .dp-calendar-nav-container {
  height: 50px !important;
  border: 1px solid #e0e0e0;
  background-color: #02a96b !important;
}

dp-day-calendar.dp-material .dp-current-day {
  border: 1px solid #02a96b !important;
}

dp-day-calendar.dp-material .dp-calendar-wrapper {
  padding: 0.6rem;
}

// dp-day-calendar.dp-material .dp-calendar-day,
// dp-day-calendar.dp-material .dp-calendar-month {

//   color: #54708b !important;
// }

dp-day-calendar.dp-material .dp-selected {
  background: #02a96b !important;
  color: #ffffff !important;
}

dp-day-calendar .dp-calendar-day {
  width: 35px !important;
  height: 33px !important;
}

dp-day-calendar.dp-material .dp-calendar-weekday {
  height: 25px;
  width: 35px !important;
  line-height: 25px;
  color: #7a7a7a;
  border: none;
}

dp-day-calendar .dp-week-number {
  position: relative !important;
  font-size: 15px !important;
  color: #02a96b !important;
  margin: 10px !important;
}

dp-day-calendar .dp-weekdays {
  font-size: 1rem !important;
  margin-bottom: 5px !important;
  margin-left: 35px !important;
}

dp-calendar-nav.dp-material .dp-nav-header-btn {
  height: 30px !important;
  width: 100px !important;
  border: none !important;
  background: #02a96b !important;
  color: #ffffff;
  outline: 0 !important;
  border-radius: 15px !important;
  max-width: 155px !important;
  padding: 0 13px !important;
}

dp-calendar-nav.dp-material .dp-nav-header-btn:hover,
dp-calendar-nav.dp-material .dp-nav-header-btn:active {
  background: rgba(0, 0, 0, 0.1) !important;
}

dp-calendar-nav.dp-material .dp-calendar-nav-left,
dp-calendar-nav.dp-material .dp-calendar-nav-right,
dp-calendar-nav.dp-material .dp-calendar-secondary-nav-left,
dp-calendar-nav.dp-material .dp-calendar-secondary-nav-right {
  border: none;
  background: none !important;
  color: #ffffff !important;
  outline: 0;
  font-size: 16px;
  padding: 0;
}

dp-month-calendar.dp-material .dp-current-month {
  border: 1px solid #02a96b !important;
}

dp-calendar-nav .dp-calendar-nav-left,
dp-calendar-nav .dp-calendar-nav-right,
dp-calendar-nav .dp-calendar-secondary-nav-left,
dp-calendar-nav .dp-calendar-secondary-nav-right {
  position: relative;
  width: 20px !important;
  cursor: pointer;
}

dp-calendar-nav .dp-current-location-btn {
  position: relative;
  top: -1px;
  height: 16px;
  width: 16px;
  vertical-align: middle;
  background: #02a96b !important;
  border: 1px solid #02a96b !important;
  outline: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px #fff;
  cursor: pointer;
}

dp-current-location-btn:hover,
dp-current-location-btn:active {
  background: rgba(0, 0, 0, 0.1) !important;
}

dp-calendar-nav .dp-calendar-nav-container-left:hover,
dp-calendar-nav .dp-calendar-nav-container-right:hover,
dp-calendar-nav .dp-calendar-nav-container-left:active,
dp-calendar-nav .dp-calendar-nav-container-right:active {
  background: rgba(0, 0, 0, 0.1) !important;
  border-radius: 50% !important;
}

dp-date-picker {
  width: 100% !important;
}

dp-date-picker.dp-material .dp-picker-input {
  height: 38px !important;
  width: 100% !important;
  font-size: 1rem !important;
}

dp-time-select .dp-time-select-controls {
  padding: 0.6rem !important;
}

// Calendario Fim

::ng-deep.theme-green .bs-datepicker-head {
  background-color: #02a96b;
}

::ng-deep.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*="select-"]:after,
.theme-green .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #02a96b;
}


.ng-select.ng-select-sm .ng-select-container,
.ng-select.ng-select-single.ng-select-sm .ng-select-container {
  height: 30px !important;
  min-height: 30px !important;
}

.sidebar .sub-menu>li.active:after {
  border-color: $cor-padrao;
}

.sidebar .nav>li.active>a,
.sidebar .nav>li.active>a:hover,
.sidebar .nav>li.active>a:focus {
  color: #fff;
  /* background: #18B7DB; */
  height: 40px;
  border-right: 3px solid $cor-padrao;
  border-radius: 50px 0px 0px 50px;
  background-color: #333;
  margin-left: 2%;
}

// mudanças layout
.header.navbar-default {
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 rgba(128, 128, 128, 0.73);
  box-shadow: 0 0 15px 0 rgba(128, 128, 128, 0.73);
}

// .sidebar {
//   top: 50px;
// }

// .sidebar-bg {
//   background: #fff;
//   top: 50px;
// }

.logo {
  // width: 170px;
  // margin-top: 5px;
  // margin-left: 30px;
  width: 150px;
  margin-top: 7px;
  margin-left: 42px;
}

.logo-login {
  width: 250px;
  margin: 0 18%;
}

.page-sidebar-minified .sidebar .nav>li>img.logo-cookgo-mini {
  width: 50px !important;
  margin-left: 5px;
  display: block !important;
}

.sidebar .nav>li>img.logo-cookgo-mini {
  display: none !important;
}

.page-sidebar-minified .sidebar .nav>li>img.logo-cookgo {
  display: none !important;
}

.sidebar .nav>li>img.logo-cookgo {
  display: block !important;
}

.page-sidebar-minified .sidebar .nav>li>img.logo-empresa-mini {
  width: 47px !important;
  height: 40px !important;
  margin-top: 10px;
  margin-left: 6px;
  display: block !important;
  border: 1.5px solid $cor-padrao;
}

.sidebar .nav>li>img.logo-empresa-mini {
  display: none !important;
}

.page-sidebar-minified .sidebar .sub-menu {
  margin-top: -40px !important;
}

.sub-menu {
  margin-top: -12px !important;
}

.login.login-with-news-feed .login-header,
.register.register-with-news-feed .register-header {
  position: relative;
  top: 0;
  margin: 0;
  left: 0;
  right: 0;
  width: auto;
  padding: 150px 60px 0;
}

// .login.login-with-news-feed, .register.register-with-news-feed {
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   padding: 0;
//   top: 0;
//   background-color: #ffffff;
// }

.button-link {
  border-radius: 4px;
  color: $cor-padrao;
  display: inline-block;
  font-weight: 550;
  letter-spacing: .25px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: inherit;
  outline: 0;
  padding: 0;
  text-align: left;
}

.login .login-content {
  padding: 30px 40px;
  color: #212529;
  width: 450px;
  margin: 0 auto;
}

.min-width-120 {
  min-width: 120px !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $cor-padrao;
}


/* NGX TREE COMPONENT */

.tree-component {
  .buttons-bar {
    width: 108px;
    justify-content: space-around;
  }

  .tree-content-main {
    border-left: 2px solid #ccc;
  }

  .child-drop-place {
    display: none;
  }

  .tree-title {
    display: flex !important;
    flex-direction: row !important;
  }

  .tree-title::before {
    background-color: #ccc;
    height: 2px;
  }

  .tree-content {
    border-left: 2px solid #ccc;
  }

  .root-title {
    .buttons-bar {
      width: 75px;
      justify-content: space-around;
    }
  }

  .show-btn,
  .hide-btn {
    color: White !important;
    background-color: #02a96b !important;
    border-color: #02a96b !important;
    padding: 10px !important;
    border-radius: 5px !important;
    width: 8px !important;
    height: 8px !important;
  }

  .show-btn:active,
  .hide-btn:active {
    border: none;
  }
}

.button-limpar {
  background-color: white;
  border-color: #02a96b;
  color: #02a96b;
}

.customPopoverNomeItemNotaFiscal {
  left: 440px !important;
  min-width: 400px !important;
  font-size: 11px !important;
}

.lista-ordenar {
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.ordenar-handle {
  top: 20px;
  left: 10px;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}

.box-ordenar {
  min-height: 55px;
  box-sizing: border-box;
  border: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: #fff;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.box-ordenar:active,
.box-ordenar:hover {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.popover-filter-datepicker {
  min-width: 325px;
  box-shadow: 0 0 10px 0 #aaa;
}

::ng-deep {
  .cdk-drag-preview {
    z-index: 9999 !important;
  }
}
