
$hover-input: #4cf5b7;
$verde-escuro: #01754b;
$cor-padrao: #02a96b;
$verde-claro: #59b593;
$cor-auxiliar: #18b7db;
$cor-texto-padrao: #212529;
$branco: #ffffff;
$cinza-1: #e9ecef;
$cinza-4: #8c8e90;
$vermelho: #ff5b57;
$preto: #000;
$border: 0 1px 20px rgba(0, 0, 0, 0.7), 0 1px 2px rgba(0, 0, 0, 0.1);
$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
$font-paragrafo: 0.9rem;
$gradient: linear-gradient(
  to right,
  $cor-padrao 0%,
  $cor-padrao 10%,
  $cor-padrao 100%
);
$a-color: $cor-padrao;

